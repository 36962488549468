import defaultConfig from './default'
import devConfig from './dev'
import stageConfig from './stage'
import prodConfig from './prod'

let config = defaultConfig;

if (window.location.hostname === process.env.REACT_APP_DEVELOPMENT_URL) config = devConfig;
if (window.location.hostname === process.env.REACT_APP_STAGING_URL) config = stageConfig;
if (window.location.hostname === process.env.REACT_APP_PRODUCTION_URL) config = prodConfig;

export default config;
