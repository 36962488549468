import { Col, Form, Row, Typography } from "antd";
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions";
import i18n from "../../../i18n/i18n";
import NewPasswordForm from "../../components/NewPasswordForm/NewPasswordForm";

const { Title } = Typography;

class OverwritePassword extends Component<any, any> {
  public static defaultProps: Partial<any> = {};

  constructor(props: any) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (localStorage.getItem("tempPassword") === undefined) {
      this.props.history.push("/dashboard");
    }
  }

  /**
   * @description send email
   * @param email
   */
  handleSubmit = (data: any) => {
    const {
      match: { params },
    } = this.props;
    console.log(data);
    this.props.setPassword(data["password"]);
    //this.props.setNewPassword(data['password'], params.token, params.project);
  };

  render = () => {
    const { form, history, newPasswordSuccess, validateTokenSuccess } =
      this.props;
    return (
      <div className="wrapper">
        <Title className="page-titel">
          <Translation>{(t) => t("login:changePassword")}</Translation>{" "}
        </Title>

        <Row>
          <Col xs={0} sm={0} md={1} lg={2} xl={2} />
          <Col
            className="login-form-fields"
            xs={24}
            sm={24}
            md={22}
            lg={20}
            xl={20}
          >
            <Row>
              <Col xs={0} sm={0} md={5} lg={7} xl={7} />
              <Col
                className="login-form-fields"
                xs={24}
                sm={24}
                md={14}
                lg={10}
                xl={10}
              >
                <>
                  <p>
                    {!newPasswordSuccess && i18n.t("login:setNewPassword.text")}
                  </p>
                  <NewPasswordForm
                    form={form}
                    newPasswordSuccess={newPasswordSuccess}
                    handleSubmit={this.handleSubmit}
                    shouldRemainInWeb
                  />
                </>
              </Col>
              <Col xs={0} sm={0} md={5} lg={7} xl={7} />
            </Row>
          </Col>
        </Row>
      </div>
    );
  };
}

const OverwritePasswordPage = Form.create<any>({ name: "overwrite-password" })(
  OverwritePassword
);
const mapStateToProps = (state: any) => {
  return {
    newPasswordSuccess: state.auth.newPasswordSuccess,
    validateTokenSuccess: state.auth.validateTokenSuccess,
  };
};

const mapDispatchToProps = (dispatch: (arg: any) => any): any => {
  return {
    setNewPassword: (password: string, token: string, project: string) =>
      dispatch(actions.setNewPassword(password, token, project)),
    validateToken: (token: string, project: string = "") =>
      dispatch(actions.validateToken(token, project)),
    setPassword: (password: string) => dispatch(actions.setPassword(password)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()<any>(OverwritePasswordPage));
