import { Alert, Button, Form, Input, Result } from "antd";
import React, { useState } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import config from "../../../../config/client.js";
import i18n from "../../../i18n/i18n";
import { INewPasswordFormProps } from "../../models/INewPasswordForm";
import { hasErrors } from "../../share/error";
import "./NewPasswordForm.scss";

const layout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
  },
};

const NewPasswordForm: React.FC<any> = (props: INewPasswordFormProps): any => {
  const {
    getFieldDecorator,
    getFieldsError,
    // getFieldError,
    // isFieldTouched,
  } = props.form;
  const { newPasswordFailed } = props;
  // Only show error after a field is touched.
  const [confirmDirty, setConfirmDirty] = useState(false);

  const handleSubmit = (e: any) => {
  
    e.preventDefault();
    props.form.validateFields((err: any, values: any) => {
      if (!err) {
        props.handleSubmit(values);
      }
    });
  };

  /**
   * @function validateToNextPassword
   * @descrioption
   * @param rule
   * @param value
   * @param callback
   */
  const validateToNextPassword = (rule: any, value: any, callback: any) => {
    const { form } = props;
    if (value && confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  const passwordValidatorRules = (rule: any, password: string, callback: any) => {
    const ret: string[] = [];
    if (password.length < 8) {
      callback(
        <Translation>
          {(t) => (
            <span>
              {t("login:validatorPassword.minLengthPassword")}
            </span>
          )}
        </Translation>
      );
    }
    let pattern = /^(?=.*\s)/;
    if (pattern.test(password)) {
      callback( 
        <Translation>
          {(t) => (
            <span>
              {t("login:validatorPassword.spaceChar")}
            </span>
          )}
        </Translation>
      );
    }
    pattern = /(?=.*[A-Z])/;
    if (!pattern.test(password)) {
      callback( 
        <Translation>
          {(t) => (
            <span>
              {t("login:validatorPassword.upperChar")}
            </span>
          )}
        </Translation>
      );
    }
    pattern = /^(?=.*[a-z])/;
    if (!pattern.test(password)) {
      callback( 
        <Translation>
          {(t) => (
            <span>
              {t("login:validatorPassword.lowerChar")}
            </span>
          )}
        </Translation>
      );
    }
    pattern = /^(?=.*[0-9])/;
    if (!pattern.test(password)) {
      callback( 
        <Translation>
          {(t) => (
            <span>
              {t("login:validatorPassword.minNum")}
            </span>
          )}
        </Translation>
      );
    }
    pattern = /^(?=.*[~`!@#$%^&*()--+={}[]|\:;"'<>,.?\/_₹])/
    if (!pattern.test(password)) {
      callback( 
        <Translation>
          {(t) => (
            <span>
              {t("login:validatorPassword.specChar")}
            </span>
          )}
        </Translation>
      );
      callback('mindestens ein Sonderzeichen');
    }
    callback()
  }

  /**
   * @function compareToFirstPassword
   * @description
   * @param rule
   * @param value
   * @param callback
   */
  const compareToFirstPassword = (rule: any, value: any, callback: any) => {
    const { form } = props;
    if (value && value !== form.getFieldValue("password")) {
      callback(
        <Translation>
          {(t) => (
            <span>
              {t("register:registerPassword.validator.newPasswordConfirm")}
            </span>
          )}
        </Translation>
      );
    } else {
      callback();
    }
  };

  const handleConfirmBlur = (e: any) => {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  };

  // props.form.validateFields();
  return (
    <>
      {!props.newPasswordSuccess && (
        <>
          {newPasswordFailed && (
              <Alert 
                message={<p>{i18n.t("policies:newPasswordError")}
                  <a style={{margin:0, padding:0, color: '#6B42A2', whiteSpace:'nowrap'}} href="mailto:hilfe@digitale-therapiebegleitung.de">hilfe@digitale-therapiebegleitung.de</a>.
                  </p>} 
                type="error" 
                showIcon 
                closable 
                />)}
          <h3>
            {i18n.t("login:setNewPassword.text")}
          </h3>
          
          <p>{i18n.t("policies:rules")}</p>
          <ul>
            <li>{i18n.t("policies:minCharLen")}</li>
            <li>{i18n.t("policies:upperChar")}</li>
            <li>{i18n.t("policies:lowChar")}</li>
            <li>{i18n.t("policies:specialChar")}</li>
            <li>{i18n.t("policies:numberChar")}</li>
          </ul>
          <Form
            {...layout}
            layout="vertical"
            autoComplete="on"
            onSubmit={handleSubmit}
          >
           
            <Form.Item
              label={i18n.t("login:registerPassword.newPassword")}
              hasFeedback
            >
                {getFieldDecorator("password", {
                  rules: [
                    {
                      required: true,
                      message: i18n.t("login:registerPassword.newPasswordEnter"),
                    },
                    {
                      validator: validateToNextPassword,
                    },
                    {
                      validator: passwordValidatorRules,
                    },
                  ],
              })(<Input.Password />)}
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {i18n.t("login:registerPassword.newPasswordRepeat")}
                </span>
              }
              hasFeedback
            >
              {getFieldDecorator("confirm", {
                rules: [
                  {
                    required: true,
                    message: (
                      <Translation>
                        {(t) => t("login:registerPassword.newPasswordRepeat")}
                      </Translation>
                    ),
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                ],
              })(<Input.Password onBlur={handleConfirmBlur} />)}
            </Form.Item>
           
            <Form.Item>
              <Button
              style={{float:'left',background: hasErrors(getFieldsError()) || props.form.getFieldValue("password") === undefined || props.form.getFieldValue("confirm") === undefined ? '#ccc' : ''}}
                type="primary"
                htmlType="submit"
                className="reset-password-form-button"
                disabled={hasErrors(getFieldsError()) || props.form.getFieldValue("password") === undefined || props.form.getFieldValue("confirm") === undefined}
              >
                <span>{i18n.t("login:button.submitPassword")}</span>
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
      {props.newPasswordSuccess && (
        <>
          <Result
            status={"success"}
            title={i18n.t("login:resetPassword.success")}
          />
          {/*<p className="saving">
            {!props.shouldRemainInWeb && (
              <div className="description">
                <p>{i18n.t("login:resetPassword.successHintMobile")}</p>
                {isAndroid ? (
                  <a
                    className="ant-btn ant-btn-primary"
                    href={config.appLinks.android}
                  >
                    {i18n.t("login:resetPassword.goToApp")}
                  </a>
                ) : (
                  !isIOS && (
                    <a
                      className="ant-btn ant-btn-primary"
                      href={config.appLinks.ios}
                    >
                      {i18n.t("login:resetPassword.goToApp")}
                    </a>
                  )
                )}
                <div className="divider" />
                <p>{i18n.t("login:resetPassword.successHintWeb")}:</p>
              </div>
            )}
            <Link className="ant-btn ant-btn-link" to="/signin">
              {i18n.t("login:resetPassword.goTologin")}
            </Link>
          </p>
                  */}
        </>
      )}
    </>
  );
};

export default NewPasswordForm;
