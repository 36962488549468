import * as actionTypes from './actionTypes';
import { baseUrlClient, caseManagement } from './../../core/auth/services/axios';
import qs from 'querystring';
import { auth } from './auth';
import config from './../../config/client';

const baseUrl = config.baseURL;
const caseUrl = config.caseManagement;

/**
 * registerStart
 */
export const registerStart = (dispatch: any) => {
  return (dispatch: any) => {};
};

/**
 *
 * @method register
 * @description step1 register new user
 * @param user
 * @param password
 */
export const register = (credential: any) => {
  let tmpUrl = '/users/prepare-activate';
  let tempCredential = {};

  if (process.env.REACT_APP_THEME_ENV !== 'comjoo') {
    tmpUrl = '/users/activate';
    tempCredential = {
      login: credential.username,
      password: credential.password,
    };
  } else {
    tempCredential = {
      username: credential.username,
      password: credential.password,
    };
  }

  return async (dispatch: any) => {
    try {
      const response = await baseUrlClient.post(
        baseUrl + tmpUrl,
        tempCredential
      );
      dispatch(registerSuccess(credential, response.data));
    } catch (error) {
      dispatch(registerFail(true));
    }
  };
};

/**
 * @method registerSuccess
 * @description save data in register LocalStorage
 * @param token
 * @param userId
 */
export const registerSuccess = (credential: any, response: any) => {
  /**
   * @description   set date timestamp in storage
   */
  var createDate = new Date();
  var createDateTimeStamp = Math.round(createDate.getTime() / 1000);
  localStorage.setItem('timeStamp', JSON.stringify(createDateTimeStamp));

  credential.position = 1;
  /**
   * @description special case already registered email if response object email=false and process.env.REACT_APP_THEME_ENV not comjoo
   */
  if (!response.email && process.env.REACT_APP_THEME_ENV !== 'comjoo') {
    /**
     * @description see in register method fallback 3 steps sanecum
     */
    credential.hash = response;
  }

  localStorage.setItem('register', JSON.stringify(credential));

  return {
    type: actionTypes.REGISTER_SUCCESS,
    step: 1,
    credential: credential,
    error: false,
  };
};

/**
 * @method registerFail
 * @param error
 */
export const registerFail = (error: any) => {
  return {
    type: actionTypes.REGISTER_FAIL,
  };
};

/**
 * @method registerEmail
 * @description get Data from LocalStorage Register and current Input Field
 */
export const registerEmail = (credential: any) => {
  const registerForm: any = localStorage.getItem('register');
  if (typeof registerForm === 'string') {
    const register = JSON.parse(registerForm);

    return async (dispatch: any) => {
      const credentialRegisterEmail = {
        login: register.username,
        password: register.password,
        eMail: credential.email,
        position: 4,
      };

      // append to storage
      register.email = credential.email;
      register.position = 3;

      try {
        const response = await baseUrlClient.post(
          baseUrl + '/users/activate',
          qs.stringify(credentialRegisterEmail)
        );
        dispatch(registerEmailSuccess(credential, register, response.data));
      } catch (error) {
        dispatch(registerEmailFail(false, credential));
      }
    };
  }
};

/**
 * @method registerEmailSuccess
 * @description save data in storage and change state
 */
export const registerEmailSuccess = (
  credential: any,
  register: any,
  response: any
) => {
  register.hash = response;
  localStorage.setItem('register', JSON.stringify(register));
  return {
    type: actionTypes.REGISTER_EMAIL_SUCCESS,
    step: 4, // TODO later is step 3 if we send email
    error: false,
    credential: credential,
  };
};

/**
 * @method registerEmailFail
 * @param error
 * @param credential
 */
export const registerEmailFail = (error: any, credential: any) => {
  return {
    type: actionTypes.REGISTER_EMAIL_FAIL,
    error: error,
    username: credential.username,
    password: credential.password,
    email: credential.email,
    credential: credential,
  };
};

/**
 * @method registerNewPassword
 * @description generate new password fields old password, new password and repeat new password
 */
export const registerNewPassword = (credential: any, history: any) => {
  const registerForm: any = localStorage.getItem('register');
  if (typeof registerForm === 'string') {
    const register = JSON.parse(registerForm);
    return async (dispatch: any) => {
      const credentialNewPassword = {
        oldPassword: register.password,
        newPassword: credential.password,
      };

      try {
        await baseUrlClient.patch(
          baseUrl + '/users/activate/' + register.hash,
          qs.stringify(credentialNewPassword)
        );

        dispatch(registerNewPasswordSuccess(credential.password));

        /**
         * @description call auth login end of onboarding process
         */
        setTimeout(() => {
          /**
           * @description see in register method fallback 3 steps sanecum
           */
          if (process.env.REACT_APP_THEME_ENV !== 'comjoo') {
            history.push('/signin');
          } else {
            dispatch(auth(register.email, credential.password, false));
          }
        }, 3000);
      } catch (error) {
        dispatch(registerNewPasswordFail(error));
      }
    };
  }
};

/**
 * @method registerNewPasswordSuccess
 * @description clear localStorage and
 */
export const registerNewPasswordSuccess = (password: any) => {
  let step = null;
  if (process.env.REACT_APP_THEME_ENV === 'comjoo') {
    step = 4;
  } else {
    step = 2;
  }
  localStorage.removeItem('register');
  localStorage.removeItem('timeStamp');
  return {
    type: actionTypes.REGISTER_NEW_PASSWORD_SUCCESS,
    step: step,
    registerForm: {
      username: '',
      password: '',
      email: '',
      error: false,
    },
  };
};

/**
 * @method registerNewPasswordFail
 */
export const registerNewPasswordFail = (error: any) => {
  return {
    type: actionTypes.REGISTER_NEW_PASSWORD_FAIL,
    error: error,
  };
};

/**
 * @method registerReset
 * @description clear local Storage step and change state
 */
export const registerReset = (credential: any) => {
  return {
    type: actionTypes.REGISTER_RESET,
    stepFormPosition: credential.position,
    registerForm: {
      username: credential.username !== 'undefinded' ? credential.username : '',
      password: credential.password !== 'undefinded' ? credential.password : '',
      email: credential.email !== 'undefinded' ? credential.email : '',
      error: false,
    },
  };
};

/**
 * @method registerReset
 * @description clear local Storage step and change state
 */
export const registerResetButton = () => {
  localStorage.removeItem('register');
  localStorage.removeItem('timeStamp');
  return {
    type: actionTypes.REGISTER_RESET,
    stepFormPosition: 0,
    registerForm: {
      username: '',
      password: '',
      email: '',
      error: false,
    },
  };
};

/**
 * @method registerCheckState
 * @description check step form register and parse state storage
 */
export const registerCheckState = () => {
  var timeStamp = localStorage.getItem('timeStamp');

  /**
   * @description clear register storage if 24 hours over
   */
  if (timeStamp !== null) {
    // current timestamp
    var currentDate = Math.round(new Date().getTime() / 1000);
    // timestamp yesterday after 24 hours
    var timeStampYesterday = currentDate - 24 * 3600;
    var is24 = parseInt(timeStamp) <= timeStampYesterday;
    if (is24) {
      localStorage.removeItem('register');
      localStorage.removeItem('timeStamp');
    }
  }

  return (dispatch: any) => {
    const register = localStorage.getItem('register');
    if (typeof register === 'string') {
      const reg = JSON.parse(register);
      if (reg.position === 'undefined') {
        let reg: any = {};
        reg.position = 1;
        dispatch(registerReset(reg));
      } else {
        dispatch(registerReset(reg));
      }
    }
  };
};

/**
 * @method sendTokenEmailValidation
 * @description
 */
export const sendTokenEmailValidation = (token: string) => {
  return async (dispatch: any) => {

    let url = `${caseUrl}/signup/validate-email/${token}`;

    try {
      await caseManagement.get(url);
      dispatch(sendTokenEmailValidationSuccess(true));
    } catch (error) {
      dispatch(sendTokenEmailValidationFail(false));
    }
  };
}


/**
 * @method sendTokenEmailValidationSuccess
 * @description
 */
export const sendTokenEmailValidationSuccess = (password: any) => {
  return {
    type: actionTypes.SEND_TOKEN_EMAIL_VALIDATION_SUCCESS,
  };
};

/**
 * @method sendTokenEmailValidationFail
 * @description
 */
export const sendTokenEmailValidationFail = (password: any) => {
  return {
    type: actionTypes.SEND_TOKEN_EMAIL_VALIDATION_FAIL,
  };
};
