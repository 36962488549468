import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import LocalStorageBackend from 'i18next-localstorage-backend';
import XHR from 'i18next-xhr-backend';
import globalDE from './translation/de/global.json';
import globalEN from './translation/en/global.json';
import loginDE from './translation/de/login.json';
import loginEN from './translation/en/login.json';
import registerDE from './translation/de/register.json';
import registerEN from './translation/en/register.json';
import errorDE from './translation/de/error.json';
import errorEN from './translation/en/error.json';
import rulesDE from './translation/de/rulesValidators.json';
import rulesEN from './translation/en/rulesValidators.json';
import LanguageDetector from 'i18next-browser-languagedetector';

const supportedLanguages = ['de']; // 'en'

const resources = {
  de: {
    translation: globalDE,
    login: loginDE,
    register: registerDE,
    error: errorDE,
    policies: rulesDE
  },
  en: {
    translation: globalEN,
    login: loginEN,
    register: registerEN,
    error: errorEN,
    policies: rulesEN
  },
};

i18n
  // lazy loading load ltranslation
  .use(Backend)
  // .use(XHR)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next
  .use(initReactI18next)
  // init i18next
  .init({
    whitelist: supportedLanguages,
    fallbackLng: 'de',
    debug: false, // debug testing
    backend: [
      LocalStorageBackend, // primary
      XHR, // fallback
    ],
    resources,

    interpolation: {
      /*
      format: function(value, format, lng) {
        if (format === 'bold') return value.toUpperCase();
        //if(value instanceof Date) return moment(value).format(format);
        return '<i>'+ value+ '</i>';
      },
      */
      useRawValueToEscape: false,
      escapeValue: false, // react already safes from xss
    },
    /*
    detection: {
      order: ["path", "navigator"]
    },
    */
    react: {
      bindI18n: 'languageChanged',
    },
  });

export default i18n;
